import Vue from "vue";
import VueRouter from "vue-router";
import page from "../view/index.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "first", component: page },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  background-image: url(@/assets/background.jpg);
  background-size: cover;
  /* height: 100vh; */
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}
body {
  margin: 0;
}
.container {
  padding: 25px 16px 80px;
  display: flex;
  flex-direction: column;
  background-size: contain;
  font-size: 14px;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 46px;
  margin-bottom: 46px;
}
input {
  padding: 16px;
  border: none;
  border-radius: 8px;
  background: #fff;
  width: -webkit-fill-available;
}
input::placeholder {
  color: #939a9e;
}
input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.label {
  font-size: 14px;
  display: block;
  margin-bottom: 14px;
  text-align: start;
}
.textarea {
  border-radius: 8px;
  background: #fff;
  width: -webkit-fill-available;
  border: none;
  padding: 16px;
  outline: none;
  resize: none;
  height: 156px;
  overflow: auto;
  font-family: 'Inter', sans-serif;
}
a {
  color: #fff;
  text-decoration: none;
}
h1 {
  font-family: 'Inter', sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.button {
  padding: 12px 24px;
  border-radius: 4px;
  background: #9da6b1;
  color: #fff;
  border: none;
  width: fit-content;
  cursor: pointer;
}
.active {
  background: #1b65bf;
}
.step-number {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 24px;
  width: 100%;
}
</style>

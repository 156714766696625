<template>
  <div>
    <FirestStep v-if="show === 1" @nextStep="nextStep" @error404="error404"></FirestStep>
    <SecondStep v-if="show === 2" @agree="agree"></SecondStep>
    <SuccessPage v-if="show === 3"></SuccessPage>
    <ErrorPage404 v-if="show === 4"></ErrorPage404>
    <Teleport to="body">
      <LoadSpinner v-if="isLoading" />
    </Teleport>
    <div class="modal" v-if="modalIsOpen">
      <div class="modal-container">
        <div class="modal-header">
          <h3>
            СОГЛАСИЕ <br />
            субъекта персональных данных на обработку его персональных данных
          </h3>
          <div class="modal-close">
            <img @click="modalIsOpen = false" src="../assets/x.svg" />
          </div>
        </div>
        <div class="modal-scrollable">
          Пользователь (субъект персональных данных) принимает решение о предоставлении своих персональных данных путем
          заполнения своих данных в форме (в том числе и обратной связи), размещенной в чат-боте «ДомКУ» на платформе
          мессенджера Telegram (адрес ТГ) (далее – Чат-бот), а именно: - фамилия, имя, отчество, адрес электронной
          почты, телефон, геопозиция, номер лицевого счета, адрес владельца лицевого счета. Пользователь, заполняя свои
          данные в формах (в том числе и обратной связи), размещенных в Чат-боте, предоставляет настоящее Согласие на
          обработку персональных данных (далее – Согласие) Обществу с ограниченной ответственностью «АФТ» (ООО «АФТ»),
          ИНН 7810971451, ОГРН 1237800016310, которое находится по адресу: 196135, Санкт-Петербург, ул. Типанова, д. 23,
          стр. 1, кв. 289, в соответствии со статьей 9 Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных
          данных». Согласие дается на обработку (включая сбор (в том числе от третьих лиц), систематизацию, накопление,
          хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование, уничтожение) с
          использованием средств автоматизации или без использования таких средств. Цель сбора и обработки персональных
          данных – информационно-технологическое обеспечение возможности оплаты коммунальных услуг жилищно-коммунального
          хозяйства с помощью Чат-бота, информирование пользователя, указавшего лицевой счет, предоставление квитанций
          по лицевому счёту, получение справочной информации и обратной связи по вопросам функционирования сервиса, сбор
          и анализ статистики по формам обратной связи, техническая поддержка пользователей. Персональные данные,
          полученные ООО «АФТ», будут храниться и обрабатываться исключительно на территории Российской Федерации.
          Настоящее согласие дается на период до истечения сроков хранения соответствующей информации или документов,
          содержащих указанную информацию, определяемых в соответствии с законодательством Российской Федерации.
          Согласие может быть отозвано в любое время на основании письменного заявления Пользователем, направленного в
          Чат-боте либо по адресу электронной почты: адрес эл. почты.
        </div>
        <div>
          <!-- <vue-recaptcha sitekey="Your key here"></vue-recaptcha> -->
        </div>
        <button @click="submit" class="button active" :disabled="isLoading">Подтверждаю</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import FirestStep from '../components/FirstStep.vue'
import SecondStep from '../components/SecondStep.vue'
import SuccessPage from '../components/SuccessPage.vue'
import ErrorPage404 from '../components/ErrorPage404.vue'
import LoadSpinner from '@/components/LoadSpinner.vue'

export default {
  name: 'TopScreen',
  components: {
    FirestStep,
    SecondStep,
    SuccessPage,
    ErrorPage404,
    LoadSpinner
  },
  data() {
    return {
      data: {},
      show: 1,
      modalIsOpen: false,
      isLoading: false
    }
  },
  created() {
    console.log(process.env.VUE_APP_BASE_URL)
  },
  methods: {
    agree(text, files) {
      this.modalIsOpen = true
      this.data.text = text
      this.data.files = files
    },
    nextStep(data) {
      this.data = data
      this.show = 2
    },
    error404() {
      this.show = 4
    },
    async submit() {
      // api
      this.isLoading = true
      const apiUrl2 = `${process.env.VUE_APP_BASE_URL}/v1/appeals`
      const requestData2 = {
        company_id: parseInt(this.data.companyId),
        user_telegram_id: parseInt(this.data.telegramId),
        type_id: parseInt(this.data.themeId),
        account_number: this.data.accId,
        contact_email: this.data.email,
        contact_phone: this.data.phone,
        messages: {
          text: this.data.text,
          files: this.data.files
        }
      }
      console.log(apiUrl2, requestData2)
      const response = await axios.post(apiUrl2, requestData2)
      console.log(response)
      this.modalIsOpen = false
      this.isLoading = false
      this.show = 3
    }
  }
}
</script>

<style scoped>
.modal {
  display: flex;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.44);
  top: 0;
  left: 0;
  position: fixed;
}
.modal-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
}
.modal-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.modal-header {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}
.modal-close img {
  width: 12px;
}
.modal-scrollable {
  background-color: #eeeeee;
  padding: 12px 8px;
  max-height: 270px; /* Максимальная высота для прокрутки */
  overflow-y: auto;
  margin: 0 -8px;
  border-radius: 14px;
}
.modal .active {
  margin-top: 24px;
}
button:disabled {
  background-color: #9da6b1;
  cursor: not-allowed;
}
</style>

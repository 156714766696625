<template>
  <div>
    <form @submit.prevent="nextPage" class="container">
      <h1>Обращение в УК</h1>
      <div class="input-group">
        <div>
          <div class="label">Введите Ваш Email *</div>
          <input
            class="input"
            type="email"
            placeholder="email@mail.ru"
            @keypress="handleKeyDown"
            @input="validateEmail"
            required
            v-model="email"
          />
        </div>
        <div>
          <div class="label">Введите Ваш номер телефона</div>
          <input
            class="input"
            type="phone"
            v-mask="'+7 (###) ###-##-##'"
            placeholder="+7 (999) 999-99-99"
            v-model="phone"
          />
        </div>
        <div>
          <div class="label">№ лицевого счета абонента</div>
          <input class="input" type="text" :placeholder="accId" disabled />
        </div>
      </div>
      <!-- <div class="input-group">
      <div v-if="themes && theme">
        <div class="label">Тема обращения или вопроса*</div>
        <VSelector :themes="themes" :theme="theme" @choose="selectChoose" />
      </div>
      <div v-if="company != null">
        <div class="label">Наименование УК</div>
        <input class="input" type="text" :placeholder="company" disabled />
      </div>
    </div> -->
      <button class="button" type="submit" :class="{ active: isEmailValid }" :disabled="!isEmailValid">
        Продолжить
      </button>
    </form>
    <div class="step-number">Шаг 1</div>
  </div>
</template>

<script>
import axios from 'axios'
// import VSelector from './VSelector'
export default {
  name: 'FirstStepView',
  // components: {
  //   VSelector
  // },
  data() {
    return {
      email: '',
      isEmailValid: false,
      phone: '',
      themes: null,
      accId: null,
      companyId: null,
      themeId: null,
      telegramId: null,
      company: null,
      theme: null,
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  mounted() {
    this.accId = this.$route.query.acc_id
    this.companyId = this.$route.query.company_id
    this.themeId = this.$route.query.theme_id
    this.telegramId = this.$route.query.user_telegram_id
    this.getThemes()
    this.getCompanyInfo()
  },
  methods: {
    validateEmail() {
      const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/
      this.isEmailValid = emailRegex.test(this.email)
    },
    handleKeyDown(event) {
      const keyCode = event.keyCode || event.which
      if ((keyCode >= 1040 && keyCode <= 1103) || (keyCode >= 1025 && keyCode <= 1105)) {
        event.preventDefault()
      }
    },
    nextPage() {
      this.$emit('nextStep', {
        accId: this.accId,
        companyId: this.companyId,
        company: this.company,
        themeId: this.themeId,
        theme: this.theme,
        email: this.email,
        phone: this.phone,
        telegramId: this.telegramId
      })
    },
    selectChoose(data) {
      this.theme = data
    },
    getThemes() {
      axios
        .get(`${this.baseUrl}/v1/appeals/appeal-types`)
        .then((response) => {
          this.themes = response.data
          this.theme = response.data.find((x) => x.id === parseInt(this.themeId)).name
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // getCurrentTheme() {
    //   this.currentTheme = this.themes.find((item) => item.appeal_ID === 1);
    //   console.log(this.themes);
    //   console.log(this.currentTheme);
    // },
    getCompanyInfo() {
      const url = `${this.baseUrl}/v1/companies/${this.companyId}`
      axios
        .get(url)
        .then((response) => {
          console.log(response.data)
          this.company = response.data
        })
        .catch((error) => {
          // Обработка ошибки
          console.error(error)
          console.error(error.response.status)
          if (error.response.status === 404) {
            this.$emit('error404')
          }
        })
    }
  }
}
</script>

<style scoped>
button:disabled {
  background-color: #9da6b1;
  cursor: not-allowed;
}
</style>

<template>
  <div class="container">
    <h1>Обращение в УК</h1>
    <div class="text">
      <div>Ваше обращение отправлено!</div>
      <div>Вас автоматически перенаправит в бот</div>
    </div>
    <div class="step-number">Готово!</div>
  </div>
</template>

<script>
export default {
  name: 'SuccessPageComponent',
  data() {
    return {
      botUrl: 'https://t.me/dom_ky_bot'
    }
  },
  created() {
    setTimeout(() => window.location.replace(this.botUrl), 3000)
  }
}
</script>

<style scoped>
.text {
  margin-top: 46px;
}
</style>

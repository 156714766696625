import FileClient from "./FileClient";

const resource = "/images";
export default {
  createImages(loadash) {
    return FileClient.post(`${resource}`, loadash);
  },
  getImagesList() {
    return FileClient.get(`${resource}`);
  },
  getImage(filename) {
    return FileClient.get(`${resource}/${filename}`, {
      responseType: "arraybuffer",
    });
  },
};

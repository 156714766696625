import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_FILE_IP ? process.env.VUE_APP_FILE_IP : 'https://domky.supplerus.com/file-to-local'
})
instance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    try {
      if (error.response.status === 400) {
        alert(`Ошибка файлового сервера \n ${error.response.data.user_alert_message}`)
      }
      if (error.response.status === 500) {
        alert(`Непредвиденная ошибка файлового сервера \n Обратитесь в техподдержку`)
      }
      return
    } catch (e) {
      console.log(e)
    }
  }
)

export default instance

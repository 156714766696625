<template>
  <div class="container">
    <h1>Обращение в УК</h1>
    <div>
      <div class="label">Текст обращения или вопроса</div>
      <textarea class="textarea" wrap="on" placeholder="Нажмите для ввода" v-model="text"></textarea>
    </div>
    <!-- <div class="description">
      <b>Пожалуйста, обратите внимание</b>, что в данный момент невозможно загрузить файлы в рамках нашего приёма
      обращений. Мы просим вас самостоятельно создавать ссылки на файлы в облачных ресурсах (напр.
      <a href="https://disk.yandex.ru">https://disk.yandex.ru</a> или
      <a href="https://www.google.ru/drive">https://www.google.ru/drive</a> ) и вставлять эти ссылки в текст обращения.
      Если это неудобно, пожалуйста, предоставьте необходимые данные текстом. (например, указание номера паспорта,
      серии, кем выдан и т. д.)
    </div> -->
    <div class="documents">
      <div class="documents__wrapper">
        <div class="documents__description">
          Форма для загрузки документов: pdf, png, jpg, jpeg, heic. Не более 10мб
        </div>
        <label class="documents__input">
          <input
            type="file"
            name="file[]"
            accept=".pdf, .jpg, .jpeg, .png, .heic"
            v-on:change="uploadImage($event)"
            ref="fileInput"
            multiple
          />
          <span class="button-files">Выбрать файл</span>
        </label>

        <div v-if="fileList.length > 0" class="documents__list">
          <div v-for="(file, index) in fileList" :key="index" class="documents__item">
            <div class="documents__name">
              <img src="../assets/file.svg" />
              <span>{{ file.file.name }}</span>
            </div>
            <div @click="removeFilesItem(index)" class="documents__remove"><img src="../assets/removeIcon.png" /></div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="isTextValid" class="button">Продолжить</button>
    <button v-else @click="createNewsImages" class="button active">Продолжить</button>
    <div class="step-number">Шаг 2</div>
  </div>
</template>

<script>
import { FileFactory } from '@/services/Files/FileFactory.js'
const files = FileFactory.get('file')
export default {
  name: 'SecondStepComponent',
  data() {
    return {
      text: '',
      fileList: [],
      fileListIds: []
    }
  },
  computed: {
    isTextValid() {
      return !this.text.length >= 1
    }
  },
  methods: {
    agree() {
      this.$emit('agree', this.text, this.fileListIds)
      console.log('this.fileListIds: ' + this.fileListIds)
    },
    addFiles(event) {
      const files = event.target.files
      for (let i = 0; i < files.length; i++) {
        this.fileList.push(files[i])
      }
    },
    uploadImage(event) {
      event.preventDefault()
      let files = this.$refs.fileInput.files
      if (files.length + this.fileList.length > 10) {
        alert('Вы можете загрузить не более 10 файлов')
        return
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i]) {
          if (files[i].size / 1024 / 1024 > 10) {
            alert('Размер файла должен быть больше 10mb')
            return
          }
          // для файлов .heic
          const fileName = files[i].name
          const fileExtension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2)
          console.log(fileExtension, fileName)
          if (
            files[i].type === 'image/jpeg' ||
            files[i].type === 'image/png' ||
            files[i].type === 'application/pdf' ||
            fileExtension.toLowerCase() === 'heic'
          ) {
            const image = { base64: null, file: files[i] }
            const reader = new FileReader()
            reader.readAsDataURL(files[i])
            reader.onload = (e) => {
              image.base64 = e.target.result
              this.fileList.push(image)
            }
          } else {
            alert('Допустимые расширения: PDF, JPG, JPEG, PNG, HEIC')
          }
        }
      }
    },
    async createNewsImages() {
      try {
        //сохранение изображения
        if (this.fileList.length === 0) {
          this.agree()
        } else {
          for (let i = 0; i < this.fileList.length; i++) {
            let fd = new FormData()
            let name = `${Date.now()} ${this.fileList[i].file.name}`
            console.log(name)
            fd.append(name, this.fileList[i].file)
            const dataImages = await files.createImages(fd)
            this.fileListIds.push(
              JSON.stringify({
                filename: dataImages.data.filename,
                saved_with_name: dataImages.data.saved_with_name
              })
            )
            console.log(this.fileList.length, i)
            if (this.fileList.length === i + 1) {
              this.agree()
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    removeFilesItem(index) {
      this.fileList.splice(index, 1)
    }
  }
}
</script>

<style scoped>
/* .container {
  background-image: url(@/assets/background.png);
  background-size: cover;
} */
h1 {
  margin-bottom: 46px;
}
.button {
  margin-top: 50px;
}
.description {
  margin-top: 24px;
}
a {
  color: #000;
  text-decoration: underline;
}
.documents {
  margin-top: 24px;
}
.documents__input {
  position: relative;
  display: inline-block;
}
.button-files {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #4ba5f4;
  border-radius: 24px;
  background: rgba(244, 244, 244, 0.6);
  padding: 10px 24px;
}
input[type='file'] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}
.documents__list {
  color: rgba(0, 163, 255, 1);
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-top: 24px;
}
.documents__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.documents__name {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 8px;
}
.documents__remove {
  color: #000;
  cursor: pointer;
}
.documents__remove img {
  width: 16px;
}
.documents__description {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
}
</style>
